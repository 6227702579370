<template>
	<Page
		v-if="pageData"
		:page-data="pageData"
	/>
</template>

<script>
import {
	defineComponent,
	computed,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import Page from '@/components/Page.vue';

import { SYSTEM_LOCALE } from '@zyro-inc/site-modules/constants';
import { getPathParams } from '@zyro-inc/site-modules/utils/page/getPathParams';
import { getPageIdFromPath } from '@zyro-inc/site-modules/utils/page/getPageIdFromPath';
import { getLanguageSwitcherLanguages } from '@zyro-inc/site-modules/utils/getLanguageSwitcherLanguages';

export default defineComponent({
	name: 'PageWrapper',
	components: {
		Page,
	},
	setup() {
		const { state } = useStore();
		const route = useRoute();
		const website = computed(() => state.website);
		const currentLocale = computed(() => {
			if (!website.value) {
				return null;
			}

			const { locale } = getPathParams({
				path: route.path,
				defaultLocale: website.value.meta.defaultLocale ?? SYSTEM_LOCALE,
				languageKeys: Object.keys(website.value.languages),
			});

			return locale;
		});

		const languageData = computed(() => (website.value ? website.value.languages[currentLocale.value] : null));

		const pageData = computed(() => (website.value ? {
			pages: languageData.value.pages,
			blocks: languageData.value.blocks,
			elements: languageData.value.elements,
			nav: languageData.value.nav,
			currentLocale: currentLocale.value,
			homePageId: languageData.value.homePageId,
			isNavHidden: languageData.value.isNavHidden,
			cookieBannerAcceptText: languageData.value.cookieBannerAcceptText,
			cookieBannerDisclaimer: languageData.value.cookieBannerDisclaimer,
			cookieBannerDeclineText: languageData.value.cookieBannerDeclineText,
			metaTitle: languageData.value.metaTitle,
			meta: website.value.meta,
			forms: website.value.forms,
			styles: website.value.styles,
			domain: website.value.domain,
			siteId: website.value.siteId,
			ecommerceShoppingCart: website.value.ecommerceShoppingCart,
			blogCategories: website.value.blogCategories,
			languageSwitcherLanguages: getLanguageSwitcherLanguages({
				languages: website.value?.languages,
				defaultLocale: website.value?.meta.defaultLocale,
			}),
			currentPageId: getPageIdFromPath({
				path: route.path,
				siteData: website.value,
			}),
			languageKeys: Object.keys(website.value.languages),
		} : null));

		return {
			pageData,
		};
	},
});
</script>
