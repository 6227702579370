import { getDomainWithoutWWWPrefix } from '@zyro-inc/site-modules/utils/domainUtils';
import {
	getCookie,
	setCookie,
} from '@zyro-inc/site-modules/utils/cookies';
import {
	computed,
	ref,
} from 'vue';
import { addDocumentElements } from '@/utils/addDocumentElements';
import { useStore } from 'vuex';
import { getHeadData } from '@/components/metas/getHeadData';

export const useCookieBanner = () => {
	const { getters } = useStore();
	const domainNameWithoutWww = getDomainWithoutWWWPrefix(window.location.hostname);
	const cookieName = `z-cookies-consent-${domainNameWithoutWww}`;
	const cookieConsent = ref(getCookie(cookieName));
	const hasUserConsentedCookieBanner = computed(() => !!cookieConsent.value);
	const hasUserAcceptedCookies = computed(() => cookieConsent.value === '1');
	const areCookiesAllowed = computed(() => cookieConsent.value !== '0');

	const acceptCookies = () => {
		setCookie(cookieName, 1, 365);
		cookieConsent.value = '1';

		const headData = getHeadData({
			currentPageData: getters.currentPageData,
			blocks: getters.blocks,
			elements: getters.elements,
			meta: getters.meta,
			currentLocale: getters.currentLocale,
			domain: getters.domain,
			siteId: getters.siteId,
			languageMetaTitle: getters.metaTitle,
		});

		addDocumentElements({
			meta: getters.meta,
			languageKeys: getters.languageKeys,
			headData,
			fonts: getters.fonts,
			siteId: getters.siteId,
			areCookiesAllowed: areCookiesAllowed.value,
		});
	};

	const declineCookies = () => {
		setCookie(cookieName, 0, 1);
		cookieConsent.value = '0';
	};

	return {
		acceptCookies,
		declineCookies,
		hasUserConsentedCookieBanner,
		hasUserAcceptedCookies,
		areCookiesAllowed,
	};
};
